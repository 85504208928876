// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  position: fixed;
  background-color: grey;
  border-radius: 4px;
  color: #ffffff;
  font-family: Arial;
  padding: 10px 16px;
  font-size: 15px;
  margin-bottom: 5px;
  transform: translateX(-50%);
}
.tooltip::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid grey;
  position: absolute;
  left: calc(50% - 5px);
  bottom: -4px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/tooltip/tooltip.component.scss","webpack://./../../ALPA%20KH/aiacambodia-alpa-fe/src/app/modules/tooltip/tooltip.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,2BAAA;ACCJ;ADAI;EACE,WAAA;EACA,QAAA;EACA,SAAA;EACA,kCAAA;EACA,mCAAA;EACA,0BAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;ACEN","sourcesContent":[".tooltip {\r\n    position: fixed;\r\n    background-color: grey;\r\n    border-radius: 4px;\r\n    color: #ffffff;\r\n    font-family: Arial;\r\n    padding: 10px 16px;\r\n    font-size: 15px;\r\n    margin-bottom: 5px;\r\n    transform:translateX(-50%);\r\n    &::before {\r\n      content: '';\r\n      width: 0;\r\n      height: 0;\r\n      border-left: 5px solid transparent;\r\n      border-right: 5px solid transparent;\r\n      border-top: 5px solid grey;\r\n      position: absolute;\r\n      left: calc(50% - 5px);\r\n      bottom: -4px;\r\n    }\r\n  }",".tooltip {\n  position: fixed;\n  background-color: grey;\n  border-radius: 4px;\n  color: #ffffff;\n  font-family: Arial;\n  padding: 10px 16px;\n  font-size: 15px;\n  margin-bottom: 5px;\n  transform: translateX(-50%);\n}\n.tooltip::before {\n  content: \"\";\n  width: 0;\n  height: 0;\n  border-left: 5px solid transparent;\n  border-right: 5px solid transparent;\n  border-top: 5px solid grey;\n  position: absolute;\n  left: calc(50% - 5px);\n  bottom: -4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
