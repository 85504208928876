// Angular
import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

// Rxjs
import { Observable } from 'rxjs';

// Ionic
import { NavController } from '@ionic/angular';

// Project
import { AuthStore } from 'src/app/stores/auth.store';

@Injectable({
  providedIn: 'root'
})
export class LoginFlowGuard  {

  private authStore: AuthStore;
  private navController: NavController;

  constructor(private injector: Injector) {
    this.authStore = this.injector.get<AuthStore>(AuthStore);
    this.navController = this.injector.get<NavController>(NavController);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const isLoggedIn = this.authStore.state.agentCode;
      if (isLoggedIn) {
        return true;
      } else {
        this.navController.navigateRoot(['login']);
        return false;
      }
  }
}
