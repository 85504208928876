// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-tab-outlet {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--zi-modal-outlet);
}

.site-tab-outlet__content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/side-tab-outlet/side-tab-outlet.component.scss","webpack://./../../ALPA%20KH/aiacambodia-alpa-fe/src/app/modules/side-tab-outlet/side-tab-outlet.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,oCAAA;EACA,+BAAA;ACCF;;ADEA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;ACCF","sourcesContent":[".site-tab-outlet {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  z-index: var(--zi-modal-outlet);\r\n}\r\n\r\n.site-tab-outlet__content {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n}\r\n",".site-tab-outlet {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: var(--zi-modal-outlet);\n}\n\n.site-tab-outlet__content {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
