// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-outlet {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--zi-modal-outlet);
}
.modal-outlet.full-- {
  height: 115%;
}
@media (min-width: 750px) {
  .modal-outlet.full-- {
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/modal-outlet/modal-outlet.component.scss","webpack://./../../ALPA%20KH/aiacambodia-alpa-fe/src/app/modules/modal-outlet/modal-outlet.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,+BAAA;ACDF;ADGE;EACE,YAAA;ACDJ;ADGI;EAHF;IAII,YAAA;ECAJ;AACF","sourcesContent":["@import '../../../scss/globals';\r\n\r\n.modal-outlet {\r\n  width: 100%;\r\n  height: 100%;\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  z-index: var(--zi-modal-outlet);\r\n\r\n  &.full-- {\r\n    height: 115%;\r\n\r\n    @media ($breakpoint-medium) {\r\n      height: 100%;\r\n    }\r\n  }\r\n}\r\n",".modal-outlet {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: var(--zi-modal-outlet);\n}\n.modal-outlet.full-- {\n  height: 115%;\n}\n@media (min-width: 750px) {\n  .modal-outlet.full-- {\n    height: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
